import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import Confetti from 'react-confetti'

import { Toast } from '@/shared/Toast'

import useQuery from '@/hooks/useQuery'

import SubmissionAudio from '@/pages/SubmissionUpload/SubmissionAudio'
import SubmissionUrl from '@/pages/SubmissionUpload/SubmissionUrl'
import SubmissionGeneralForm from '@/pages/SubmissionUpload/GeneralForm'
import VideomancyPrompt from '@/pages/SubmissionUpload/VideomancyPrompt'

const SubmissionUploadEdit = ({ projectId, submissionData, roleId, currentUser }) => {
  // two paths come to this page
  // submissions/:id/edit
  // submissions/news?project_id
  const { submission } = submissionData
  console.log(submission)
  const urlParams = new URLSearchParams(window.location.search)
  const firstTimeSubmission = urlParams.get('success') === 'true'
  const { getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        project: {},
        roles: [],
        loading: true
      }
    )
  const { project, selectedRole, roles, loading } = state

  const updateSelectedRole = (e) => {
    const role = roles.find((role) => parseInt(role.id, 10) === parseInt(e.target.value, 10))
    setState({ selectedRole: role })
  }

  useEffect(() => {
    getRequest(`/api/v3/projects/${projectId}/start_submission`, { role_id: roleId }, (err, jsonData) => {
      if (err) { return }

      setState({
        project: jsonData.project,
        selectedRole: jsonData.selectedRole,
        roles: jsonData.roles,
        loading: false
      })
    })
  }, [])

  if (loading) return null

  return <>
    <div className='max-w-xl mx-auto mt-10'>
      { firstTimeSubmission && <Confetti width={window.innerWidth} gravity={0.25} colors={['#f7784e', '#00b0e7', '#6452a2']} numberOfPieces={300} recycle={false} height={window.innerHeight} /> }

      { !submission?.id && <div className='text-gray-900 dark:text-gray-100 bg-cccblue bg-opacity-20 p-2 rounded-md'>
        <div className=''> Select Role: </div>
        <select name='select_role' className="w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" onChange={updateSelectedRole} defaultValue={selectedRole?.id}>
          <option value={null}></option>
          {roles.map((role, idx) => (
            <option key={`rk${role.id}`} value={role.id}>{role.name}</option>
          ))}
        </select>
      </div> }

      { selectedRole?.submissionKind.includes('audio') && <SubmissionAudio project={project} submission={submission} selectedRole={selectedRole} /> }
      { selectedRole?.submissionKind.includes('url') && <SubmissionUrl project={project} submission={submission} selectedRole={selectedRole} /> }
      { submission?.id && <SubmissionGeneralForm initSubmission={submission} /> }
    </div>
    { submission?.id && <div className='mt-2'><VideomancyPrompt initialSubmission={submission} /></div> }

    <Toast />
  </>
}

export default SubmissionUploadEdit

SubmissionUploadEdit.propTypes = {
  submissionData: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  currentUser: PropTypes.object,
  roleId: PropTypes.number
}
